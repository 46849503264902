<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-container
      fluid
    >
      <b-row>

        <b-col
          cols="12"
          class="px-1"
        >
          <b-form-group
            label="Search"
            label-for="filterGroupSearch"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filterGroupSearch"
              v-model="tableCategories.filter.search"
              placeholder="search here"
              autocomplete="off"
              debounce="1000"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="px-1 mb-2"
        >
          <b-button
            size="sm"
            type="button"
            variant="success"
            @click.prevent="createGroup"
          >
            Add Group
          </b-button>
        </b-col>

      </b-row>

      <b-row
        class="mt-1"
      >
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableCategories"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :items="tableProvider"
            :busy="tableCategories.busy"
            :filter="tableCategories.filter"
            :fields="tableCategories.fields"
            :stacked="tableCategories.stacked"
            :sort-by.sync="tableCategories.sortBy"
            :sort-desc.sync="tableCategories.sortDesc"
            :sort-direction="tableCategories.sortDirection"
            :filter-included-fields="tableCategories.filterOn"
            :current-page="tableCategories.currentPage"
            :per-page="tableCategories.perPage"
          >
            <template #cell(index)="{ index }">
              {{ tableRowNumber(tableCategories, index) }}
            </template>

            <template #cell()="{ value }">
              <div class="text-md-nowrap">
                {{ value }}
              </div>
            </template>

            <template #cell(action)="{ item }">
              <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                <b-button
                  size="sm"
                  variant="success"
                  @click.prevent="editGroup(item)"
                >
                  Edit
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableCategories.perPage"
            :options="tableCategories.pageOptions"
            :disabled="tableCategories.busy"
            class="w-100 w-md-25"
            size="sm"
          />
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableCategories.currentPage"
            :total-rows="tableCategories.totalRows"
            :per-page="tableCategories.perPage"
            :disabled="tableCategories.busy"
            aria-controls="table"
            prev-text="Prev"
            next-text="Next"
            first-number
            last-number
            pills
          />
        </b-col>
      </b-row>

    </b-container>

    <b-modal
      id="modalGroup"
      scrollable
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      title="Edit Group"
      aria-hidden="false"
    >
      <ValidationObserver
        ref="formGroup"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="group code"
                vid="group_code"
                rules="required|max:100"
              >
                <b-form-group
                  label="Code"
                  label-for="group_code"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="group_code"
                    v-model="group.group_code"
                    type="text"
                    maxlength="30"
                    autocomplete="off"
                    placeholder="enter group code here"
                    :state="(errors.length > 0 ? false : null)"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="(errors.length > 0)"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="group name"
                vid="group_name"
                rules="required|max:100"
              >
                <b-form-group
                  label="Name"
                  label-for="group_name"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="group_name"
                    v-model="group.group_name"
                    type="text"
                    maxlength="100"
                    autocomplete="off"
                    placeholder="enter group name here"
                    :state="(errors.length > 0 ? false : null)"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="(errors.length > 0)"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group>
                <b-form-checkbox
                  id="is_active"
                  v-model="group.active"
                  switch
                  value="1"
                  name="is_active"
                  unchecked-value="0"
                  :disabled="state.busy"
                >
                  Is Active?
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

        </form>
      </ValidationObserver>

      <template #modal-footer="{cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click.prevent="validateGroup"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click.prevent="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import QUERY from 'lodash'
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { core } from '@/config/pluginInit'
import { SAdminGroup } from '@/services'

export default {
  name: 'AdminCategories',

  middleware: [
    'auth',
    'admin'
  ],

  mixins: [
    MISC,
    FORMATTER
  ],

  metaInfo: () => ({
    title: 'Categories'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      group: {
        id: null,
        group_code: null,
        group_name: null,
        active: 1
      },
      tableCategories: {
        busy: false,
        filter: {
          search: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 6, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', class: 'min-w-150px', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'group_code', class: 'min-w-175px' },
          { mobile: 3, key: 'group_name', class: 'min-w-175px' },
          { mobile: 4, key: 'active', class: 'text-center', formatter: this.yesOrNo },
          { mobile: 5, key: 'updated_at', class: 'min-w-150px', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableCategories.busy = true
      return await SAdminGroup.get({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search
      }).then(({ data }) => {
        this.tableCategories.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        this.tableCategories.totalRows = 0
        return []
      }).finally(() => {
        this.tableCategories.busy = false
      })
    },

    createGroup () {
      this.state.editing = false

      this.group.id = null
      this.group.group_code = null
      this.group.group_name = null
      this.group.active = 1

      this.$bvModal.show('modalGroup')
    },

    editGroup (group) {
      this.state.editing = true

      this.group.id = group.id
      this.group.group_code = group.group_code
      this.group.group_name = group.group_name
      this.group.active = group.active

      this.$bvModal.show('modalGroup')
    },

    async validateGroup () {
      await this.$refs.formGroup.validate().then(
        async validGroup => {
          if (!validGroup) {
            return this.swalInvalid()
          }

          this.swalConfirm({
            html: () => {
              if (this.state.editing) {
                return '<h6>Save your changes on this Group?</h6>'
              }
              return '<h6>Create this Group?</h6>'
            },
            preConfirm: () => {
              this.state.busy = true
              if (this.state.editing) {
                return this.updateGroup()
              }
              return this.submitGroup()
            }
          })
        }
      )
    },

    async submitGroup () {
      return await SAdminGroup.post(this.group).then(
        ({ data: { message } }) => {
          this.$bvModal.hide('modalGroup')
          this.swalSuccess(message).then(() => {
            this.$refs.tableCategories.refresh()
          })
        }
      ).catch(({ message }) => {
        this.$refs.formGroup.setErrors(message)
      }).finally(() => {
        this.state.busy = false
      })
    },

    async updateGroup () {
      this.state.busy = true
      return await SAdminGroup.put(this.group).then(
        ({ data: { message, group } }) => {
          this.$bvModal.hide('modalGroup')
          this.swalSuccess(message).then(() => {
            const tableCategoriesRow = QUERY.find(this.$refs.tableCategories.localItems, {
              id: Number(group.id)
            })
            if (tableCategoriesRow) {
              tableCategoriesRow.group_code = group.group_code
              tableCategoriesRow.group_name = group.group_name
              tableCategoriesRow.active = group.active
              tableCategoriesRow.updated_at = group.updated_at
            }
          })
        }
      ).catch(({ message }) => {
        this.$refs.formGroup.setErrors(message)
      }).finally(() => {
        this.state.busy = false
      })
    }
  }
}
</script>
